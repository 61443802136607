export const CREATE_ROOM = "CREATE_ROOM";

export const ROOM_CREATED = "ROOM_CREATED";

export const LEAVE_ROOM = "LEAVE_ROOM";

export const LEAVE_FROM_ROOM = "LEAVE_FROM_ROOM";

export const JOIN_USER = "JOIN_USER";

export const USER_JOINED = "USER_JOINED";

export const USER_LEAVE = 'USER_LEAVE';

export const USER_JOINED_TO_ROOM = "USER_JOINED_TO_ROOM";

export const DELETE_ROOM = "DELETE_ROOM";
